import React from 'react';
import { useIntl } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import LandingSearchForm from './LandingSearchForm/LandingSearchForm';
import { Heading } from '../../../components';
import { isOriginInUse } from '../../../util/search';
import { useConfiguration } from '../../../context/configurationContext';
import { types as sdkTypes } from '../../../util/sdkLoader';

import css from './SectionHero.module.css';

const { LatLng, LatLngBounds } = sdkTypes;

function SectionHero(props) {
  const { history, viewport } = props;

  const intl = useIntl();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();

  const handleSubmit = values => {
    const {
      dates = null,
      distance = null,
      keywords = null,
      location = null,
      tournamentdetails = null,
      game = null,
      ...rest
    } = values || {};

    // Initialize searchQuery with potentially present, non-null values
    const searchQuery = {};
    if (dates) searchQuery.dates = dates;
    if (distance) searchQuery.distance = distance;
    if (keywords) searchQuery.keywords = keywords;
    const { search = 'Deutschland', selectedPlace } = location || {};

    const defaultBounds = new LatLngBounds(
      new LatLng(55.1286491, 15.041832),
      new LatLng(47.270238, 5.866315)
    );

    const defaultOrigin = new LatLng(51.1334813439932, 10.0183432948567);

    const { origin = defaultOrigin, bounds = defaultBounds } = selectedPlace || {};
    const originMaybe = isOriginInUse(config) ? { origin } : {};

    Object.assign(searchQuery, { ...originMaybe, address: search, bounds });

    if (tournamentdetails) searchQuery['pub_tournamentdetails'] = tournamentdetails;

    // Add prefixed keys from `rest`
    Object.keys(rest).forEach(key => {
      searchQuery[`pub_${key}`] = rest[key];
    });

    // Ensure game key is prefixed properly
    if (game) {
      searchQuery['pub_game'] = game;
    }

    // Modify `searchQuery` for rules keys as per the new requirements
    Object.keys(searchQuery).forEach(key => {
      if (key.includes('rule')) {
        // Check if key contains "rule"
        const val = searchQuery[key];
        if (Array.isArray(val)) {
          // Ensure it is an array
          if (val.length > 1) {
            delete searchQuery[key]; // Remove from query if array length is greater than 1
          } else if (val.length === 1) {
            searchQuery[key] = val[0]; // Assign the first element if array length is exactly 1
          }
        }
      }
    });

    const isDefault = searchQuery?.address === 'Deutschland';

    if (isDefault) {
      delete searchQuery.distance;
    }

    // if (typeof window !== 'undefined') {
    //   window.location.href = createResourceLocatorString(
    //     'SearchPage',
    //     routeConfiguration,
    //     {},
    //     searchQuery
    //   );
    // }

    // Use the updated searchQuery with correct key prefixes
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchQuery));
  };

  const title = intl.formatMessage({ id: 'SectionHero.title' });
  const description = intl.formatMessage({ id: 'SectionHero.description' });

  return (
    <div className={css.heroContainer}>
      <div className={css.heroBg}>
        <div className={css.contentWidth}>
          <div className={css.heroContent}>
            <Heading as="h1" rootClassName={css.heading}>
              {title}
            </Heading>
            <p>{description}</p>
            <div className={css.heroSearchSec}>
              <LandingSearchForm
                initialValues={{ distance: '20' }}
                rootClassName={css.landingSearchForm}
                onSubmit={handleSubmit}
                intl={intl}
                viewport={viewport}
                isHeroSearch={true}
                config={config}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHero;
