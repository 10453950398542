import React from 'react';

import css from './Sections.module.css';
import classNames from 'classnames';
import { NamedLink, PrimaryButton } from '../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const steps = [
  {
    img:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/75/1a94bb2fa374eaeaa8b7232b04e51a7d1dc2dc?auto=format&fit=clip&h=400&w=400&s=7af5bd1383fa0477dc4181f6b1da17b4',
    title: '1. Finde ein Turnier',
    description:
      'Suche und stöbere nach Turnieren. Filtere die Suche nach dem richtigen Trading Card Game und anderen von dir gewählten Kriterien.',
  },
  {
    img:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/c9/d081f25cdef56f31375e5cf2d2a32fddd6c381?auto=format&fit=clip&h=400&w=400&s=8b69bc05978f5f43069945e6216c85d6',
    title: '2. Registriere dich',
    description:
      'Registriere dich für Trading Card Turniere und bezahle direkt online oder später vor Ort. Schaue außerdem welche Community-Mitglieder sich ebenfalls registriert haben.',
  },
  {
    img:
      'https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/70/e080588f076494adb9ef3f69bafddc2c02c6f7?auto=format&fit=clip&h=400&w=400&s=73879b57a323e66f0b0f8d9e90ff3a46',
    title: '3. Beweise dein Können!',
    description:
      'Bei Fragen nutze die Chat-Funktion, um direkt mit den Veranstaltern Kontakt aufzunehmen. Viel Spaß beim Sammelkarten Turnier!',
  },
];

function Step({ step }) {
  return (
    <div className={css.tournamentContent}>
      <div className={css.tournamentImg}>
        <img src={step.img} alt="img" />
      </div>
      <div className={css.tournamentInfo}>
        {step.title && <h4>{step.title}</h4>}
        {step.description && <p>{step.description}</p>}
      </div>
    </div>
  );
}

function SectionTournamentRegistration(props) {
  const { intl, viewport } = props;
  const title = 'Mühelos für Turniere registrieren';
  const description = 'Finde ein passendes Turnier und registriere dich. Ganz easy.';
  const history = useHistory();
  return (
    <div className={classNames(css.tournamentRegisterSec, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className={css.sectionContent}>
          {steps.map((step, index) => (
            <Step key={index} step={step} />
          ))}{' '}
        </div>
        <div className={css.freeRegistrationSec}>
          <PrimaryButton
            className={css.createAccountBtn}
            type="button"
            onClick={() => history.push('/signup')}
          >
            Account kostenfrei erstellen
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default SectionTournamentRegistration;
