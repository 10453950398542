import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { format, isAfter } from 'date-fns';
import { stringifyDateToISO8601 } from '../../../../util/dates';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../../util/urlHelpers';
import { isArrayLength } from '../../../../util/genericHelpers';
import moment from 'moment';

import css from './DateRangerPicker.module.css';

const DateRangePickerComponent = props => {
  const {
    dateRange,
    setDateRange,
    isLandingDateRange,
    onDateRangeChange,
    rootClassName,
    dateLabel,
    openDirection,
  } = props;
  const location = useLocation();
  const search = location?.search ? parse(location.search) : null;

  const [focusedInput, setFocusedInput] = useState(null);

  useEffect(() => {
    if (search) {
      const parsedDates = search ? search?.dates && search?.dates.split(',') : null;
      const parsedStartDate = search && isArrayLength(parsedDates) ? moment(parsedDates[0]) : null;
      const parsedEndDate = search && isArrayLength(parsedDates) ? moment(parsedDates[1]) : null;
      setDateRange({ startDate: parsedStartDate, endDate: parsedEndDate });
    }
  }, []);

  const formatValue = dates => {
    const { startDate, endDate } = dates || {};
    const start = startDate && stringifyDateToISO8601(startDate);
    const end = endDate && stringifyDateToISO8601(endDate);
    return start && end ? `${start},${end}` : null;
  };

  const handleDateChange = ({ startDate, endDate }) => {
    // If endDate is not set, use startDate as endDate for single-day selection
    if (startDate && !endDate) {
      endDate = startDate;
    }

    // Update your state here
    setDateRange({ startDate, endDate });
    onDateRangeChange(formatValue({ startDate, endDate }));
  };

  const today = new Date();
  const todayFormatted = format(today, 'MM/dd/yyyy');
  // const orientation =
  //   typeof window !== 'undefined' && window.matchMedia('(max-width: 1023px)').matches
  //     ? 'vertical'
  //     : 'horizontal';

  const orientation = 'vertical';

  return (
    <div
      className={classNames(
        {
          [css.landingDateRange]: isLandingDateRange,
        },
        rootClassName
      )}
    >
      {isLandingDateRange && <label htmlFor="dateRange">{dateLabel}</label>}
      <DateRangePicker
        startDate={dateRange ? dateRange.startDate : null}
        endDate={dateRange ? dateRange.endDate : null}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        isOutsideRange={day => moment().isAfter(day, 'day')}
        showClearDates
        startDateId="start_date"
        endDateId="end_date"
        startDatePlaceholderText={'Ab'}
        endDatePlaceholderText={'Bis'}
        orientation={orientation}
        hideKeyboardShortcutsPanel={openDirection}
      />
    </div>
  );
};

export default DateRangePickerComponent;
