import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Page, LayoutSingleColumn } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { useConfiguration } from '../../context/configurationContext';

import css from './LandingPage.module.css';

//Static assets
import facebookImage from '../../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../../assets/biketribe-twitter-sharing-600x314.jpg';
import SectionHero from './SectionHero/SectionHero';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import SectionTournament from './Sections/SectionTournament';
import SectionAboutTournazone from './Sections/SectionAboutTournazone';
import SectionTournamentRegistration from './Sections/SectionTournamentRegistration';
import SectionOrganizeTournament from './Sections/SectionOrganizeTournament';
import SectionTradingPlatform from './Sections/SectionTradingPlatform';

//Section pages

export const LandingPageComponent = props => {
  const { sectionHeroSearchKey, intl, scrollingDisabled, history, viewport } = props;

  const config = useConfiguration();

  const marketplaceName = config.marketplaceName;

  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  const [scroll, setScroll] = useState(false);

  const isHeaderSticky = scroll ? css.sticky : '';

  useEffect(() => {
    let isMounted = true;
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        if (isMounted) {
          setScroll(window.scrollY > 0);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        isMounted = false;
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const listingFields = config.listing.listingFields;
  const categories = listingFields.find(l => l.key === 'category');

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={
          <TopbarContainer
            isLandingPage={true}
            isThemeBgNav={false}
            isHeaderSticky={isHeaderSticky}
          />
        }
        footer={<FooterContainer />}
      >
        <div className={css.pageContent}>
          <SectionHero
            viewport={viewport}
            history={history}
            sectionHeroSearchKey={sectionHeroSearchKey}
          />
          <SectionTournament viewport={viewport} intl={intl} />
          <SectionAboutTournazone viewport={viewport} intl={intl} />
          <SectionTournamentRegistration viewport={viewport} intl={intl} />
          <SectionTradingPlatform viewport={viewport} intl={intl} />
          <SectionOrganizeTournament viewport={viewport} intl={intl} />
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

LandingPageComponent.defaultProps = {
  sectionHeroSearchKey: 'pub_focus=has_all%3A',
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({});

const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
