import React from 'react';

import css from './Sections.module.css';
import classNames from 'classnames';
import { NamedLink, PrimaryButton } from '../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function SectionTradingPlatform(props) {
  const { intl, viewport } = props;
  const title = 'Die Plattform für Trading Card Turniere';
  const description =
    'Tournazone schafft Transparenz in der Trading Card Turnierszene. Jedem Sammelkartenfan wird ermöglicht, Turniere in Deutschland zu finden und zu organisieren - ohne dabei auf verschiedene Plattformen angewiesen zu sein.';
  const link = 'Mehr dazu';

  const history = useHistory();

  return (
    <div className={classNames(css.tradingPlatformSec, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.tradingInfo}>
          <h4>{title}</h4>
          <p>{description}</p>
          <PrimaryButton
            className={css.link}
            type="button"
            onClick={() => history.push('/p/about')}
          >
            {link}
          </PrimaryButton>
        </div>
        <div className={css.tradingImg}>
          <img
            src="https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/2e/4ed99c139ddc04806f8c75973750de3f677ca1?auto=format&fit=clip&h=800&w=800&s=1e25a9317f79ef9e53c403085bb0b51b"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default SectionTradingPlatform;
