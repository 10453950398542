import React from 'react';

import css from './Sections.module.css';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

function SectionAboutTournazone(props) {
  const { intl, viewport } = props;
  const title = 'Was ist Tournazone?';
  return (
    <div className={classNames(css.tournamentSec, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <h2>{title}</h2>
        </div>
        <div className={css.aboutTournaZoneSec}>
          <iframe
            src="https://www.youtube-nocookie.com/embed/fXpsxTPCceM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default SectionAboutTournazone;
